// @ts-ignore
import removeTrailingZeros from 'remove-trailing-zeros';
import formatNumber from 'simple-format-number';
import * as uuid from 'uuid';
// @ts-ignore
// @ts-ignore
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import Utility from '~/services/Utility';

import { type BoardContent, type GetterBoardContent } from './community';
import { type MenuItem, type UserGnbCategory } from './gnb/type';
import { type Coupon, type DisplayProduct, PaymentButtonCode, type Product, type ProductOption } from './main/type';

export default class UtilPayment {
  static getSelectedOption(product: Product, selectedOptionSeq: number | null): ProductOption | null | undefined {
    if (selectedOptionSeq == null) {
      return null;
    }
    return product.productOption?.find((option) => option.optionSeq == selectedOptionSeq);
  }

  static getMonthlyPrice(
    product: Product,
    monthPlan: number | null | undefined,
    selectedOptionSeq?: number | null,
    isWithComma = false,
    isTotalPrice = false,
    point = 0,
    selectedCoupon?: Coupon,
  ): string {
    if (Utility.hasMonthlyPlan(product.monthPlan) == false || isTotalPrice) {
      monthPlan = 1;
    }

    let selectedOption: ProductOption | null | undefined = null;
    if (selectedOptionSeq != null) {
      selectedOption = this.getSelectedOption(product, selectedOptionSeq);
    }

    let productPrice = 0;

    if (product.classPrice?.earlyPayment != null) {
      // earlyPayment 가격이 있다.
      productPrice = product.classPrice.displayPrice ?? 0;
    } else if (product.discountPrice == null) {
      productPrice = product.price ?? 0;
    } else {
      productPrice = product.discountPrice ?? 0;
    }

    // 옵션이 있는 경우, 옵션값 추가
    if (selectedOption) {
      productPrice = productPrice + selectedOption.optionPrice;
    }

    if (selectedCoupon) {
      productPrice =
        productPrice - (this.getCouponDiscountPrice(product, selectedCoupon, selectedOptionSeq, false) as number);
    }
    if (point != null) {
      productPrice = productPrice - point;
    }

    let priceResult = productPrice / monthPlan!;

    if (priceResult < 0) {
      priceResult = 0;
    }

    if (isWithComma) {
      return Utility.setCommaPer3Digit(priceResult.toFixed(0));
    }
    return priceResult.toFixed(0);
  }

  static getEstimatedPrice(
    product: Product,
    monthPlan: number | null | undefined,
    selectedOptionSeq?: number | null,
    isWithComma = false,
    isTotalPrice = false,
    point = 0,
    selectedCoupon?: Coupon,
  ): string {
    if (Utility.hasMonthlyPlan(product.monthPlan) == false || isTotalPrice) {
      monthPlan = 1;
    }

    let selectedOption: ProductOption | null | undefined = null;

    if (selectedOptionSeq != null) {
      selectedOption = this.getSelectedOption(product, selectedOptionSeq);
    }

    let productPrice = 0;

    if (product.classPrice?.earlyPayment != null) {
      // earlyPayment 가격이 있다.
      productPrice = product.classPrice.displayPrice;
    } else if (product.discountPrice == null) {
      productPrice = product.price;
    } else {
      productPrice = product.discountPrice!;
    }

    if (product.stayPaymentYn === 'Y') {
      productPrice = product.discountPrice!;
    }

    if (selectedOption) {
      // 옵션이 있다.
      // 해당 옵션 값을 더해줌.
      productPrice = productPrice + selectedOption.optionPrice;
    }

    if (selectedCoupon) {
      productPrice =
        productPrice - (this.getCouponDiscountPrice(product, selectedCoupon, selectedOptionSeq, false) as number);
    }
    if (point != null) {
      productPrice = productPrice - point;
    }

    if (isWithComma) {
      return Utility.setCommaPer3Digit(productPrice.toFixed(0));
    }
    return productPrice.toFixed(0);
  }

  static getMonthlyPriceWithCouponApply(
    product: Product | Product,
    monthPlan: number | null,
    coupon: Coupon | null,
    selectedOptionSeq: number | null,
    isWithComma = false,
  ): string {
    const price = parseInt(this.getMonthlyPrice(product, monthPlan, selectedOptionSeq, false, true));
    let priceResult = 0;

    if (Utility.hasMonthlyPlan(product.monthPlan) == false) {
      monthPlan = 1;
    }
    if (coupon) {
      priceResult = this.getCouponPrice(price, coupon);
    }
    priceResult = priceResult / monthPlan!;
    if (isWithComma) {
      return Utility.setCommaPer3Digit(priceResult.toFixed(0));
    }
    return priceResult.toFixed(0);
  }

  static getCouponDiscountPrice(
    product: Product,
    coupon: Coupon | null | undefined,
    selectedOptionSeq?: number | null,
    isWithComma = true,
  ): string | number {
    const totalPrice = this.getMonthlyPrice(product, product.monthPlan, selectedOptionSeq, false, true);
    let discountPrice = 0;
    if (coupon != null) {
      discountPrice = this.getCouponPrice(parseInt(totalPrice), coupon, true, true);
    } else {
      // coupon 없음
      discountPrice = 0;
    }
    if (isWithComma) {
      return Utility.setCommaPer3Digit(Utility.toFixed(discountPrice));
    } else {
      return discountPrice;
    }
  }

  static getDiscountPercentageValue(price: number, discountPrice: number | null): number {
    try {
      if (!price || isNaN(price)) {
        return 0;
      }
      return Math.round(((price - (discountPrice || 0)) / price) * 100);
    } catch (e) {
      return 0;
    }
  }

  static getDiscountPercentage(product: Product): string {
    // (170000 - 99000) / 170000 * 100 = 41.18
    const percentage = this.getDiscountPercentageValue(
      product.price,
      parseInt(this.getMonthlyPrice(product, 1, null, false, true)),
    );

    // 0/0 은 NaN 이 나오기 때문에 0으로 바꿔준다.
    if (isNaN(percentage)) {
      return '0%';
    }

    return `${percentage.toFixed(0)}%`;
  }

  static getCouponPrice(
    productPrice: number,
    coupon: Coupon,
    isGetOnlyDiscountPirce = false,
    isUseDiscountLimit = true,
  ): number {
    const isDiscountLimit = isUseDiscountLimit ? this.hasDiscountLimit(coupon) : false;

    let discountPriceResult = 0;
    if (coupon.discountType == 'P') {
      // percentage
      discountPriceResult = productPrice * (coupon.discountPrice / 100);
    } else if (coupon.discountType == 'W') {
      // 원
      discountPriceResult = coupon.discountPrice;
    } else {
      alert('쿠폰 타입이 없습니다.');
    }
    if (isDiscountLimit == true) {
      // has limit
      if (coupon.maxDiscountPrice < discountPriceResult) {
        discountPriceResult = coupon.maxDiscountPrice;
      }
    }
    if (isGetOnlyDiscountPirce == true) {
      return discountPriceResult;
    }
    return productPrice - discountPriceResult < 0 ? 0 : productPrice - discountPriceResult;
  }

  static hasDiscountLimit(coupon: Coupon): boolean {
    const noLimitPrice = coupon.maxDiscountPrice == null || coupon.maxDiscountPrice == 0;
    return noLimitPrice == false;
  }

  static getMonthlyPriceFromOption(price: number, monthPlan?: number, isWithComma = false): string {
    if (Utility.hasMonthlyPlan(monthPlan) == false) {
      monthPlan = 1;
    }

    price = price / monthPlan!;

    if (isWithComma) {
      return Utility.setCommaPer3Digit(Utility.toFixed(price));
    }
    return price.toFixed(0);
  }

  static setSingleOptionDisable(buttonCode: PaymentButtonCode): boolean {
    switch (buttonCode) {
      case PaymentButtonCode.ACTIVE:
        return false;
      case PaymentButtonCode.WAIT_REQUEST:
        return false;
      case PaymentButtonCode.ENDED:
        return true;
      case PaymentButtonCode.ALARM_REQUEST:
        return true;
      case PaymentButtonCode.ALARM_CANCEL:
        return false;
      case PaymentButtonCode.WAIT_CANCEL:
        return false;
      case PaymentButtonCode.AUTH_NOT_ALLOW:
        return true;
      case PaymentButtonCode.ALARM_COMPLETED:
        return true;
      case PaymentButtonCode.ALARM_OPEN_AT:
        return true;
      case PaymentButtonCode.WAIT_REQUESTED:
        return false;
    }
  }

  static getButtonOptionText(buttonCode: PaymentButtonCode): string {
    switch (buttonCode) {
      case PaymentButtonCode.ACTIVE:
        return '강의 구매하기';
      case PaymentButtonCode.WAIT_REQUEST:
        return '대기신청';
      case PaymentButtonCode.ENDED:
        return '판매종료';
      case PaymentButtonCode.ALARM_REQUEST:
        return '오픈 알림 신청하기';
      case PaymentButtonCode.ALARM_CANCEL:
        return '오픈 알림 취소하기';
      case PaymentButtonCode.WAIT_CANCEL:
        return '대기 취소';
      case PaymentButtonCode.AUTH_NOT_ALLOW:
        return '권한이 없습니다.';
      case PaymentButtonCode.ALARM_COMPLETED:
        return '오픈 알림 신청 완료';
      case PaymentButtonCode.ALARM_OPEN_AT:
        return '{OPEN_DATE} 오픈 예정';
      case PaymentButtonCode.WAIT_REQUESTED:
        return '대기신청 완료';
    }
  }
}

// ACTIVE(1, "결제하기", false),
// WAIT_REQUEST(2, "대기신청", false),
// ENDED(3, "판매종료", true),
// ALARM_REQUEST(4, "오픈 알림 신청하기", false),
// ALARM_CANCEL(5, "오픈 알림 취소하기", false),
// WAIT_CANCEL(6, "대기 취소", false),
// AUTH_NOT_ALLOW(7, "권한이 없습니다.", true),
// ALARM_REQUESTED(8, "오픈 알림 신청 완료", true),
// ALARM_OPEN_AT(9, "{OPEN_DATE} 오픈 예정", true),
// WAIT_REQUESTED(10, "대기신청 완료", true),
